import {
	GET_COMMISIONS_WEEK_TOTALS,
	GET_COMMISIONS_RETAIL_PROFIT,
	GET_COMMISIONS_START_BONUS,
	GET_COMMISIONS_TEAM_COMMISION,
	GET_COMMISIONS_MATCHING_BONUS,
	GET_COMMISIONS_BONUS_POOL,
	GET_COMMISIONS_LIFESTYLE_BONUS,
	GET_COMMISIONS_TOP_EARNERS,
	GET_COMMISIONS_ORDERS,
	GET_COMMISIONS_REFUNDS,
	GET_COMMISIONS_PROMOTIONS,
	APPROVE_COMMISIONS,
	RELEASE_COMMISIONS,
	DOWNLOAD_WEEKLY_COMMISSIONS_REPORT,
	GET_ECOMMERCE_PROMO_BONUS,
	GET_DUDD_BONUS,
	GET_STRIVE_FOR_FIVE_BONUS,
	GET_RETAIL_PROFIT_ONLINE_BONUS,
	GET_REFERRAL_BONUS_MULTIPLIER,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class WeeklyCommissions {
	constructor() {
		const axios = new Req(apiFilters);
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getTotals(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_WEEK_TOTALS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRetailProfits(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_RETAIL_PROFIT;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getStartBonus(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_START_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRbm(weekNumber, options) {
		const { method, endpoint } = GET_REFERRAL_BONUS_MULTIPLIER;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTeamCommission(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_TEAM_COMMISION;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getMatchingBonus(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_MATCHING_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTopEarners(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_TOP_EARNERS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBonusPool(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_BONUS_POOL;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getLifestyleBonus(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_LIFESTYLE_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDUDD(weekNumber, options) {
		const { method, endpoint } = GET_DUDD_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getEcommercePromotion(weekNumber, options) {
		const { method, endpoint } = GET_ECOMMERCE_PROMO_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getStriveForFive(weekNumber, options) {
		const { method, endpoint } = GET_STRIVE_FOR_FIVE_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOnlineRetailProfit(weekNumber, options) {
		const { method, endpoint } = GET_RETAIL_PROFIT_ONLINE_BONUS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOrders(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_ORDERS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRefunds(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_REFUNDS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPromotions(weekNumber, options) {
		const { method, endpoint } = GET_COMMISIONS_PROMOTIONS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	approveCommisions(payload) {
		const { method, endpoint } = APPROVE_COMMISIONS;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	releaseCommisions(payload) {
		const { method, endpoint } = RELEASE_COMMISIONS;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadExcel(data) {
		const { method, endpoint } = DOWNLOAD_WEEKLY_COMMISSIONS_REPORT;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default WeeklyCommissions;
