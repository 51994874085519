<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col>
				<template v-if="!weeksLoading && weeksHasData">
					<b-row>
						<b-col class="col-4">
							<b-button
								v-if="parseInt(weekNumber, 10) > parseInt(weeksData[weeksData.length-1].attributes.number, 10)"
								:class="windowWidth === 'xs' ? 'mb-2' : ''"
								variant="success"
								class="btn float-left bg-primary-alt"
								@click="changeWeek('previous')">
								{{ translate('previous', { week: (parseInt(weekNumber, 10) - 1) }) }}
							</b-button>
						</b-col>
						<b-col class="col-4">
							<select
								v-model="weekNumber"
								style="height:35px;"
								class="w-100 mx-auto center-dropdown"
								@change="changeWeek('change')">
								<option
									v-for="item in weeksData"
									:key="item.id"
									:value="item.attributes.number">
									{{ `${translate('week')} ${item.attributes.number}` }}
								</option>
							</select>
						</b-col>
						<b-col class="col-4">
							<b-button
								v-if="parseInt(weekNumber, 10) < parseInt(weeksData[0].attributes.number, 10)"
								variant="success"
								class="btn bg-primary-alt float-right"
								@click="changeWeek('next')">
								{{ translate('next', { week: (parseInt(weekNumber, 10) + 1) }) }}
							</b-button>
						</b-col>
					</b-row>
					<hr>
				</template>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="weeksLoading" />
				<template v-if="!totalsLoading && totalsHasData && !weeksLoading">
					<template v-if="totalsData.show_commissions">
						<b-row>
							<!-- Week details (number and dates) -->
							<b-col
								:class="{
									'col-6 col-sm-6': admin.includes($user.details().type) && !$route.query.user_id,
									'col-12': !admin.includes($user.details().type) || $route.query.user_id,
								}">
								<template v-if="windowWidth === 'xs'">
									<h5>{{ `${translate('week')} ${weekNumber}` }}</h5>
									<h5>{{ currentWeek.to }}</h5>
									<h5>{{ currentWeek.from }}</h5>
								</template>
								<template v-else>
									<h5 class="align-item-top">
										{{ `${translate('week_from_to', { week: weekNumber, to: currentWeek.to, from: currentWeek.from })} | ${translate(currentWeek.status) }` }}
									</h5>
									<br>
									<b-row v-if="!isAffiliateCommission">
										<!-- Total sales -->
										<b-col
											v-if="admin.includes($user.details().type) && !$route.query.user_id"
											class="col-md-6 col-12">
											<icon-card-widget
												:big-label="totalsData.total_sales.amount"
												:medium-label="`${totalsData.total_sales.percentage}%`"
												:small-label="translate('total_weekly_sales')" />
										</b-col>
										<!-- Total Commission -->
										<b-col
											class="col-12"
											:class="{
												'col-md-6': admin.includes($user.details().type) && !$route.query.user_id,
												'col-md-3': !admin.includes($user.details().type) || $route.query.user_id,
											}">
											<icon-card-widget
												:big-label="totalsData.total_commissions.amount"
												:medium-label="`${totalsData.total_commissions.percentage}%`"
												:small-label="translate('total_commissions')" />
										</b-col>
										<!-- Bvs summary -->
										<b-col
											v-if="($route.query.user_id || !admin.includes($user.details().type)) && !isAffiliateCommission"
											class="col-12 col-md-9">
											<b-card
												body-class="pt-2 pb-1 mt-1">
												<div class="row">
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.left_bv }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('left_current_volume') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.carryover_left }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('left_carryover_volume') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.total_left_bv }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('total_left_bv') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ cyclesInfo.deducted_bv.left }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('left_side_cycle') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.right_bv }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('right_current_volume') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.carryover_right }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('right_carryover_volume') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.total_right_bv }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('total_right_bv') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ cyclesInfo.deducted_bv.right }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('right_side_cycle') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0">
															{{ translate(totalsData.summary.rank ? totalsData.summary.rank : 'no_rank') }}
															<template v-if="subRankIndicator(totalsData.summary, 'sub_rank') !== null">
																<img
																	:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(totalsData.summary, 'sub_rank')}_indicator.png`"
																	width="17px"
																	class="ml-1 mb-1"
																	alt="sub_rank">
															</template>
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('rank') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ cyclesInfo.total }}
															<span
																id="excess-cycles"
																class="fa fa-info-circle text-info pointer drb-pbv-popover-trigger" />
															<b-popover
																target="excess-cycles"
																triggers="hover"
																custom-class="drb-pbv-popover p-0"
																boundary-padding="0"
																:placement="['xs','sm'].includes(windowWidth) ? 'top' : 'right'">
																<div
																	class="p-2 rounded-0 m-0">
																	<div class="row no-gutters">
																		<div class="col">
																			<b>{{ translate('cycles_to_rank') }}: </b> {{ cyclesInfo.rank_cycles }}
																			<br>
																			<b>{{ translate('excess_cycles') }}: </b>  {{ cyclesInfo.excess_cycles }}
																		</div>
																	</div>
																</div>
															</b-popover>
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('earned_cycles') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.week_earnings }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('total_earnings') }}
														</div>
													</div>
													<div class="col-12 col-md-3">
														<div class="h5 mb-0 mb-1">
															{{ totalsData.summary.total_earnings }}
														</div>
														<div class="medium-label mb-2 text-muted">
															{{ translate('all_time_earnings') }}
														</div>
													</div>
												</div>
											</b-card>
										</b-col>
									</b-row>
								</template>
							</b-col>
							<!-- Corporate buttons to complete, review... week commissions -->
							<b-col
								v-if="!$route.query.user_id"
								class="col-6 col-sm-6">
								<b-row>
									<b-col class="mb-2">
										<b-button
											v-if="$can('commissions', 'approve')"
											:variant="getButtonsStatus('approve').value ? 'success' : 'primary'"
											:disabled="!getButtonsStatus('approve').value"
											class="btn float-right commission-btn"
											@click="handleButtons('approve')">
											{{ translate(getButtonsStatus('approve').translation) }}
										</b-button>
									</b-col>
								</b-row>
								<b-row>
									<b-col class="mb-2">
										<b-button
											v-if="$can('commissions', 'confirm')"
											:variant="getButtonsStatus('confirm').value ? 'success' : 'primary'"
											:disabled="!getButtonsStatus('confirm').value"
											class="btn float-right commission-btn"
											@click="handleButtons('confirm')">
											{{ translate(getButtonsStatus('confirm').translation) }}
										</b-button>
									</b-col>
								</b-row>
								<b-row>
									<b-col class="mb-2">
										<b-button
											v-if="$can('commissions', 'approve')"
											:variant="getButtonsStatus('release').value ? 'success' : 'primary'"
											:disabled="!getButtonsStatus('release').value"
											class="btn float-right commission-btn"
											@click="handleButtons('release')">
											{{ translate(getButtonsStatus('release').translation) }}
										</b-button>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-button
											v-if="$can('commissions', 'payout')"
											:variant="getButtonsStatus('go').value ? 'success' : 'primary'"
											:disabled="!getButtonsStatus('go').value"
											class="btn float-right commission-btn"
											@click="handleButtons('go')">
											{{ translate('go_to_commission') }}
										</b-button>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<!-- Commission totals for small screen (appears above totals) -->
						<b-row
							v-if="windowWidth === 'xs'">
							<!-- Corporate totals that show commissions percent over week sales -->
							<b-col
								v-if="admin.includes($user.details().type) && !$route.query.user_id"
								class="col-12 my-2">
								<icon-card-widget
									:big-label="totalsData.total_sales.amount"
									:medium-label="`${totalsData.total_sales.percentage}%`"
									:small-label="translate('total_weekly_sales')" />
							</b-col>
							<!-- User totals that only commission percent -->
							<b-col class="col-12">
								<icon-card-widget
									:big-label="totalsData.total_commissions.amount"
									:medium-label="`${totalsData.total_commissions.percentage}%`"
									:small-label="translate('total_commissions')" />
							</b-col>
							<b-col
								v-if="($route.query.user_id || !admin.includes($user.details().type)) && !isAffiliateCommission"
								class="col-12">
								<b-card
									body-class="pt-2 pb-1 mt-1">
									<div class="row">
										<div class="col-6">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.left_bv }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('left_current_volume') }}
											</div>
										</div>
										<div class="col-6 text-right">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.right_bv }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('right_current_volume') }}
											</div>
										</div>
										<div class="col-6">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.carryover_left }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('left_carryover_volume') }}
											</div>
										</div>
										<div class="col-6 text-right">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.carryover_right }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('right_carryover_volume') }}
											</div>
										</div>
										<div class="col-6">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.total_left_bv }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('total_left_bv') }}
											</div>
										</div>
										<div class="col-6 text-right">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.total_right_bv }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('total_right_bv') }}
											</div>
										</div>
										<div class="col-6">
											<div class="h5 mb-0 mb-1">
												{{ cyclesInfo.deducted_bv.left }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('left_side_cycle') }}
											</div>
										</div>
										<div class="col-6 text-right">
											<div class="h5 mb-0 mb-1">
												{{ cyclesInfo.deducted_bv.right }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('right_side_cycle') }}
											</div>
										</div>
										<div class="col-6">
											<div class="h5 mb-0">
												{{ translate(totalsData.summary.rank ? totalsData.summary.rank : 'no_rank') }}
												<template v-if="subRankIndicator(totalsData.summary, 'sub_rank') !== null">
													<img
														:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(totalsData.summary, 'sub_rank')}_indicator.png`"
														width="17px"
														class="ml-1 mb-1"
														alt="sub_rank">
												</template>
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('rank') }}
											</div>
										</div>
										<div class="col-6 text-right">
											<div class="h5 mb-0 mb-1">
												{{ cyclesInfo.total }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('earned_cycles') }}
											</div>
										</div>
										<div class="col-6">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.week_earnings }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('total_earnings') }}
											</div>
										</div>
										<div class="col-6 text-right">
											<div class="h5 mb-0 mb-1">
												{{ totalsData.summary.total_earnings }}
											</div>
											<div class="medium-label mb-2 text-muted">
												{{ translate('all_time_earnings') }}
											</div>
										</div>
									</div>
								</b-card>
							</b-col>
						</b-row>
						<hr>
						<b-row>
							<!-- User totals that only commission percent -->
							<b-col
								v-if="windowWidth !== 'xs' && isAffiliateCommission"
								class="col-6">
								<icon-card-widget
									:big-label="totalsData.total_commissions.amount"
									:medium-label="`${totalsData.total_commissions.percentage}%`"
									:small-label="translate('total_commissions')" />
							</b-col>
							<b-col
								v-for="(bonuses, index) in totalsDataBonusesGroup"
								:key="index"
								class="col-12 col-md-6">
								<b-row
									v-for="bonus in bonuses"
									:key="bonus">
									<b-col class="col-12 col-sm-6">
										<h5 :class="windowWidth === 'xs' ? 'text-left' : 'text-right'">
											{{ translate(bonus) }}
										</h5>
									</b-col>
									<b-col class="col-6 col-sm-3">
										<p class="text-right">
											{{ totalsData.bonuses[bonus].amount }}
										</p>
									</b-col>
									<b-col class="col-6 col-sm-2">
										<p class="text-right">
											{{ totalsData.bonuses[bonus].percentage }}%
										</p>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<!-- Corporate export button -->
						<b-row v-if="admin.includes($user.details().type)">
							<b-col>
								<b-button
									class="float-right"
									variant="success"
									@click="downloadExcel">
									<i class="fas fa-file-excel" /> {{ translate('export') }}
								</b-button>
							</b-col>
						</b-row>
						<hr>
						<b-row>
							<b-col />
						</b-row>
						<b-row>
							<b-col class="col-12">
								<b-nav
									tabs
									class="mt-4">
									<template v-for="(bonuses, key) in totalsData.bonuses">
										<li
											v-if="Number(totalsData.bonuses[key].amount_value) > 0"
											:key="key"
											:class="active === key ? 'nav-link active': 'text-gray-dark'"
											class="list-group-item btn text-left pointer"
											@click="active = key; changeWeek('tab')">
											{{ translate(key) }}
										</li>
									</template>
									<li
										v-if="admin.includes($user.details().type) && !$route.query.user_id"
										:class="active === 'top_earner' ? 'nav-link active': 'text-gray-dark'"
										class="list-group-item btn text-left pointer"
										@click="active = 'top_earner'; changeWeek('tab')">
										{{ translate('top_earner') }}
									</li>
									<!-- <li
										v-if="admin.includes($user.details().type) && !$route.query.user_id"
										:class="active === 'orders' ? 'nav-link active': 'text-gray-dark'"
										class="list-group-item btn text-left pointer"
										@click="active = 'orders'; changeWeek('tab')">
										{{ translate('orders') }}
									</li> -->
									<li
										v-if="totalsData.show_refunds || false"
										:class="active === 'refunds' ? 'nav-link active': 'text-gray-dark'"
										class="list-group-item btn text-left pointer"
										@click="active = 'refunds'; changeWeek('tab')">
										{{ translate('refunds') }}
									</li>
								</b-nav>
							</b-col>
						</b-row>
						<component :is="active" />
					</template>
					<template v-if="!totalsData.show_commissions">
						<b-row>
							<b-col>
								<b-alert
									variant="warning"
									show>
									{{ translate('null_totals_commissions') }}
								</b-alert>
							</b-col>
						</b-row>
					</template>
				</template>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="totalsLoading"
					:has-data="totalsHasData" />
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WeeklyCommissions from '@/util/WeeklyCommissions';
import Weeks from '@/util/Weeks';
import {
	WeeklyCommissions as messages, Commissions, Grids, TransactionCategories, Ranks,
} from '@/translations';
import RetailProfit from '@/views/Reports/WeeklyCommissions/RetailProfit';
import FastStartBonus from '@/views/Reports/WeeklyCommissions/FastStartBonus';
import TeamCommission from '@/views/Reports/WeeklyCommissions/TeamCommission';
import { YMDHMS_FORMAT, MDY_FORMAT } from '@/settings/Dates';
import LeadershipMatchingBonus from '@/views/Reports/WeeklyCommissions/LeadershipMatchingBonus';
import LeadershipBonusPool from '@/views/Reports/WeeklyCommissions/LeadershipBonusPool';
import EcommercePromotion from '@/views/Reports/WeeklyCommissions/EcommercePromotion';
import LifestyleBonus from '@/views/Reports/WeeklyCommissions/LifestyleBonus';
import TopEarners from '@/views/Reports/WeeklyCommissions/TopEarners';
// import Orders from '@/views/Reports/WeeklyCommissions/Orders';
import Promotions from '@/views/Reports/WeeklyCommissions/Promotions';
import WindowSizes from '@/mixins/WindowSizes';
import { admin, affiliate } from '@/settings/Roles';
import DoubleUpDoubleDown from '@/views/Reports/WeeklyCommissions/DoubleUpDoubleDown';
import StriveForFive from '@/views/Reports/WeeklyCommissions/StriveForFive';
import OnlineRetailProfit from '@/views/Reports/WeeklyCommissions/OnlineRetailProfit';
import ReferralBonusMultiplier from '@/views/Reports/WeeklyCommissions/ReferralBonusMultiplier';
import Refunds from '@/views/Reports/WeeklyCommissions/Refunds';
import IconCardWidget from '@/components/IconCardWidget';
import SubRanks from '@/mixins/SubRanks';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'WeeklyCommissions',
	messages: [messages, Commissions, Grids, TransactionCategories, Ranks],
	components: {
		retail_profit: RetailProfit,
		fast_start_bonus: FastStartBonus,
		team_commission: TeamCommission,
		leadership_matching_bonus: LeadershipMatchingBonus,
		leadership_bonus_pool: LeadershipBonusPool,
		lifestyle_bonus: LifestyleBonus,
		top_earner: TopEarners,
		// orders: Orders,
		ecommerce_promotion: EcommercePromotion,
		double_up_double_down: DoubleUpDoubleDown,
		referral_bonus_multiplier: ReferralBonusMultiplier,
		promotions: Promotions,
		strive_for_five: StriveForFive,
		online_retail_profit: OnlineRetailProfit,
		refunds: Refunds,
		IconCardWidget,
	},
	mixins: [WindowSizes, SubRanks],
	data() {
		return {
			totals: new WeeklyCommissions(),
			approveCommisions: new WeeklyCommissions(),
			weeks: new Weeks(),
			weekNumber: '',
			userId: {},
			active: '',
			alert: new this.$Alert(),
			admin,
			affiliate,
			currentWeek: {},
			dateFormat: YMDHMS_FORMAT,
			monthFormat: MDY_FORMAT,
			status: '',
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		weeksLoading() {
			return !!this.weeks.data.loading;
		},
		weeksErrors() {
			return this.weeks.data.errors;
		},
		cyclesInfo() {
			try {
				return this.totalsData.summary.cycles;
			} catch (error) {
				return {
					deducted_bv: {
						left: 0,
						right: 0,
					},
					total: 0,
				};
			}
		},
		weeksData() {
			try {
				const { data } = this.weeks.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		weeksHasData() {
			if (this.weeksData === null || this.weeksData === undefined) {
				return false;
			}
			const response = Object.keys(this.weeksData).length;
			return !!response;
		},
		totalsLoading() {
			return !!this.totals.data.loading;
		},
		totalsErrors() {
			return this.totals.data.errors;
		},
		totalsData() {
			try {
				const { data } = this.totals.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		totalsDataBonusesGroup() {
			try {
				const secondColumn = Object.keys(Object.assign([], this.totalsData.bonuses));
				const bonusesCount = secondColumn.length;
				if (bonusesCount > 5 && this.windowWidth !== 'xs') {
					const firstColumn = secondColumn.splice(0, Math.floor(bonusesCount / 2));
					return [firstColumn, secondColumn];
				}

				return [secondColumn];
			} catch (e) {
				console.error('error al transformar:', e);
				return [];
			}
		},
		totalsHasData() {
			if (this.totalsData === null || this.totalsData === undefined) {
				return false;
			}
			const response = Object.keys(this.totalsData).length;
			return !!response;
		},
		isAffiliateCommission() {
			try {
				const isAdminReviewing = this.admin.includes(this.$user.details().type) && this.$route.query.user_id;
				const hasNotSummaryData = Object.keys(this.totalsData.summary).length === 0;
				const isAffiliate = this.$user.details().type === affiliate;

				return (isAdminReviewing && hasNotSummaryData) || isAffiliate;
			} catch (error) {
				return false;
			}
		},
	},
	mounted() {
		if (this.$route.params.week_id) {
			this.weekNumber = this.$route.params.week_id;
		}
		if (this.$route.params.user_id) {
			this.$route.query.user_id = this.$route.params.user_id;
			this.userId = { user_id: this.$route.params.user_id };
		}
		this.getTotals();
	},
	methods: {
		async getTotals() {
			try {
				const totals = await this.totals.getTotals(this.weekNumber, this.userId);
				this.status = totals.status;

				[(this.active)] = Object.keys(totals.bonuses);
				if (this.$route.params.commission) {
					Object.keys(totals.bonuses).forEach((key) => {
						if (this.$route.params.commission === key || this.$route.params.commission === 'top_earner' || this.$route.params.commission === 'refunds') {
							this.active = this.$route.params.commission;
						}
					});
				} else {
					let newTab = '';
					Object.keys(totals.bonuses).forEach((key) => {
						if (totals.bonuses[key].amount_value > 0 && newTab.length === 0) {
							newTab = key;
						}
					});
					if (newTab.length > 0) {
						this.active = newTab;
						this.$router.replace({ name: 'WeeklyCommissions', params: { week_id: this.weekNumber, commission: newTab, ...this.userId }, query: { ...this.$route.query } });
						return;
					}
				}

				const weeks = await this.weeks.getWeeks();
				weeks.forEach((item) => {
					if (parseInt(item.attributes.number, 10) === parseInt(this.weekNumber, 10)) {
						this.currentWeek = {
							from: this.$moment(item.attributes.from_date.date).format(this.monthFormat),
							to: this.$moment(item.attributes.to_date.date).format(this.monthFormat),
							number: item.attributes.number,
							approved: item.attributes.approved,
							status: item.attributes.status,
						};
					}
					return {};
				});
			} catch (e) {
				this.alert.toast('error', this.translate('default_error_message'));
			}
		},
		changeWeek(type) {
			let week = parseInt(this.weekNumber, 10);
			if (type === 'previous') week -= 1;
			else if (type === 'next') week += 1;
			this.$router.push({ name: 'WeeklyCommissions', params: { week_id: week, commission: this.active, ...this.userId }, query: { ...this.$route.query } });
		},
		handleButtons(type) {
			if (['approve', 'confirm', 'release'].includes(type)) {
				const trans = {
					title: this.translate('password'),
				};
				const options = {
					buttonText: this.translate('ok'),
					cancelButtonText: this.translate('cancel'),
					emptyPasswordMessage: this.translate('password_is_required'),
				};
				if (['approve', 'confirm'].includes(type)) trans.text = this.translate('approve_commissions');
				if (type === 'release') trans.text = this.translate('release_commissions');
				this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
					const payload = {
						password: password.value,
						week_number: parseInt(this.weekNumber, 10),
					};
					if (['approve', 'confirm'].includes(type)) {
						this.approveCommisions.approveCommisions(payload).then(() => {
							this.alert.toast('success', this.translate('week_approved_success'));
							this.getTotals();
						}).catch(() => {
							let errorName = 'default_error_message';
							if (typeof this.approveCommisions.data.errors.errors.password !== 'undefined') {
								errorName = 'incorrect_password';
								delete this.approveCommisions.data.errors.errors.password;
							}
							this.alert.toast('error', this.translate(errorName));
						});
					} else if (type === 'release') {
						this.approveCommisions.releaseCommisions(payload).then(() => {
							this.alert.toast('success', this.translate('week_released_success'));
							this.getTotals();
						}).catch(() => {
							let errorName = 'default_error_message';
							if (typeof this.approveCommisions.data.errors.errors.password !== 'undefined') {
								errorName = 'incorrect_password';
								delete this.approveCommisions.data.errors.errors.password;
							}
							this.alert.toast('error', this.translate(errorName));
						});
					}
				}).catch(() => {});

				return null;
			}
			return this.$router.push({ name: 'WeeklyPayouts', params: { week_id: this.currentWeek.number } });
		},
		getButtonsStatus(type) {
			if (['completed', 'reviewed'].includes(this.status)) {
				if (type === 'approve') return { value: true, translation: 'complete_review_approve' };
				if (type === 'confirm') return { value: false, translation: 'complete_approval_confirmation' };
				if (type === 'release') return { value: false, translation: 'commission_release' };
				return { value: false };
			} if (this.status === 'approved') {
				if (type === 'approve') return { value: false, translation: 'release_approve' };
				if (type === 'confirm') return { value: true, translation: 'complete_approval_confirmation' };
				if (type === 'release') return { value: false, translation: 'commission_release' };
				return { value: false };
			} if (this.status === 'confirmed') {
				if (type === 'approve') return { value: false, translation: 'release_approve' };
				if (type === 'confirm') return { value: false, translation: 'release_approval_confirmed' };
				if (type === 'release') return { value: true, translation: 'commission_release' };
				return { value: false };
			}
			if (type === 'approve') return { value: false, translation: 'release_approve' };
			if (type === 'confirm') return { value: false, translation: 'release_approval_confirmed' };
			if (type === 'release') return { value: false, translation: 'release' };
			return { value: true };
		},
		downloadExcel() {
			const totals = new WeeklyCommissions();
			totals.downloadExcel({ week_number: this.weekNumber, ...this.userId }).then(() => {
				const { response } = totals.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('week_from_to', { week: this.weekNumber, to: this.currentWeek.to, from: this.currentWeek.from })} - ${this.translate(this.currentWeek.status)}.xlsx`);
				this.$el.appendChild(link);
				link.click();
			});
		},
	},
};
</script>
<style>
.alignable:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	height: 75%;
}
.align-item-top {
	display: inline-block;
	vertical-align: top;
}
</style>
<style scoped>
.list-group-item:last-child {
	margin-bottom: -1px;
}
.bonus-row {
	max-height: 20px;
}
.export-button {
	position: absolute;
	bottom: 1px;
	right: 15px;
}
</style>
