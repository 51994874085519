<template>
	<b-row>
		<b-col>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th class="border-top-0">
								#
							</th>
							<th class="border-top-0">
								{{ translate('order_id') }}
							</th>
							<th class="border-top-0">
								{{ translate('invoice_id') }}
							</th>
							<th class="border-top-0">
								{{ translate('user_id') }}
							</th>
							<th class="border-top-0">
								{{ translate('user_type') }}
							</th>
							<th class="border-top-0">
								{{ translate('full_name') }}
							</th>
							<!-- <th class="border-top-0 text-right">
								{{ translate('subtotal') }}
							</th>
							<th class="border-top-0 text-right">
								{{ translate('shipping') }}
							</th>
							<th class="border-top-0 text-right">
								{{ translate('duties') }}
							</th> -->
							<th class="border-top-0 text-right">
								{{ translate('total') }}
							</th>
							<th class="border-top-0 text-right">
								{{ translate('refund_amount') }}
							</th>
							<th class="border-top-0">
								{{ translate('order_date') }}
							</th>
							<th class="border-top-0">
								{{ translate('date_refunded') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<tr
							v-for="(item, index) in data"
							:key="index">
							<td class="align-middle">
								{{ `${(((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1))}${item.attributes.is_adjustment ? '*' : ''}` }}
							</td>
							<td class="align-middle">
								{{ item.attributes.order_id }}
							</td>
							<td
								v-if="item.attributes.invoice_id.length > 0"
								class="align-middle">
								{{ item.attributes.invoice_id }}
							</td>
							<td class="align-middle">
								<span
									v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
									{{ item.attributes.user_id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.user_id }}
								</span>
							</td>
							<td class="align-middle">
								{{ translate(item.attributes.user_type) }}
							</td>
							<td class="align-middle">
								{{ item.attributes.user_full_name }}
							</td>
							<!-- <td class="align-middle text-right">
								{{ item.attributes.base_subtotal }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.base_shipping }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.base_tax }}
							</td> -->
							<td class="align-middle text-right">
								{{ item.attributes.base_total }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.base_refunded }}
							</td>
							<td class="align-middle">
								{{ item.attributes.date_added }}
							</td>
							<td class="align-middle">
								{{ item.attributes.date_refunded }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
			<b-pagination
				v-if="pagination.total_pages > 1"
				v-model="pagination.current_page"
				:total-rows="pagination.total"
				:per-page="pagination.per_page"
				align="center"
				@change="getDataFiltered" />
			<div />
		</b-col>
	</b-row>
</template>

<script>

import WeeklyCommissions from '@/util/WeeklyCommissions';
import {
	WeeklyCommissions as messages, Grids, Users, Report,
} from '@/translations';
import { PAGINATION } from '@/settings/RequestReply';
import { admin, distributor } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';

export default {
	name: 'Refunds',
	messages: [messages, Grids, Users, Report],
	mixins: [DashboardRedirect],
	data() {
		return {
			orders: new WeeklyCommissions(),
			userId: {},
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			return !!this.data.length;
		},
		pagination() {
			return this.orders.data.pagination;
		},
	},
	mounted() {
		if (this.$route.params.week_id) {
			this.weekNumber = this.$route.params.week_id;
		}
		if (this.$route.params.user_id) {
			this.$route.query.user_id = this.$route.params.user_id;
			this.userId = { user_id: this.$route.params.user_id };
		}
		this.getData();
	},
	methods: {
		getData() {
			this.orders.getRefunds(this.weekNumber, this.userId);
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters, ...this.userId };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.orders.getRefunds(this.weekNumber, options);
		},
	},
};
</script>
