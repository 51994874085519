<template>
	<b-row>
		<b-col>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th class="border-top-0">
								#
							</th>
							<th
								class="border-top-0">
								{{ translate('user_id') }}
							</th>
							<th
								class="border-top-0">
								{{ translate('username') }}
							</th>
							<th
								class="border-top-0 text-right">
								{{ translate('total') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<tr
							v-for="(item, index) in data"
							:key="index">
							<td class="align-middle">
								{{ index + 1 }}
							</td>
							<td class="align-middle">
								<span
									v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
									{{ item.attributes.user_id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.user_id }}
								</span>
							</td>
							<td class="align-middle">
								{{ item.attributes.username }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.amount }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
			<div />
		</b-col>
	</b-row>
</template>

<script>

import WeeklyCommissions from '@/util/WeeklyCommissions';
import { WeeklyCommissions as messages, Grids } from '@/translations';
import { admin, distributor } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';

export default {
	name: 'RetailProfit',
	messages: [messages, Grids],
	mixins: [DashboardRedirect],
	data() {
		return {
			topEarners: new WeeklyCommissions(),
			admin,
			distributor,
		};
	},
	computed: {
		loading() {
			return !!this.topEarners.data.loading;
		},
		data() {
			try {
				const { data } = this.topEarners.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			return !!this.data.length;
		},
	},
	mounted() {
		if (this.$route.params.week_id) {
			this.weekNumber = this.$route.params.week_id;
		}
		this.getData();
	},
	methods: {
		getData() {
			this.topEarners.getTopEarners(this.weekNumber);
		},
	},
};
</script>
