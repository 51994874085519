<template>
	<b-row>
		<b-col>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th class="border-top-0">
								#
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('user_id')">
								{{ translate('user_id') }}
								<sort field="user_id" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('username')">
								{{ translate('username') }}
								<sort field="username" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('rank_id')">
								{{ translate('rank') }}
								<sort field="rank_id" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('level')">
								{{ translate('level') }}
								<sort field="level" />
							</th>
							<th
								class="border-top-0 pointer text-right"
								@click="sortByField('amount')">
								{{ translate('total') }}
								<sort field="amount" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('earning_amount')">
								{{ translate('earning') }}
								<sort field="earning_amount" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('from_user_id')">
								{{ translate('from_user_id') }}
								<sort field="from_user_id" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('from_username')">
								{{ translate('from_username') }}
								<sort field="from_username" />
							</th>
							<th
								class="border-top-0 pointer text-right"
								@click="sortByField('commission_amount')">
								{{ translate('team_comm') }}
								<sort field="commission_amount" />
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<tr
							v-for="(item, index) in data"
							:key="index">
							<td class="align-middle">
								{{ `${(((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1))}${item.attributes.is_adjustment ? '*' : ''}` }}
							</td>
							<td class="align-middle">
								<span
									v-if="admin.includes($user.details().type)"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
									{{ item.attributes.user_id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.user_id }}
								</span>
							</td>
							<td class="align-middle">
								{{ item.attributes.username }}
							</td>
							<td class="align-middle d-flex align-items-center">
								{{ translate(item.attributes.rank) }}
								<template v-if="subRankIndicator(item.attributes, 'current_sub_rank') !== null">
									<img
										:src="`${S3_PATH}/assets/ranks/${themeName}/${subRankIndicator(item.attributes, 'current_sub_rank')}_indicator.png`"
										class="ml-1 mb-1"
										alt="sub_rank">
								</template>
							</td>
							<td class="align-middle">
								{{ item.attributes.level }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.amount }}
							</td>
							<td class="align-middle">
								{{ item.attributes.earning_amount }}
							</td>
							<td class="align-middle">
								<span
									v-if="admin.includes($user.details().type)"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.from_user_id.toString() })">
									{{ item.attributes.from_user_id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.from_user_id }}
								</span>
							</td>
							<td class="align-middle">
								{{ item.attributes.from_username }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.commission_amount }}
							</td>
						</tr>
					</tbody>
				</table>
				<p>{{ translate('foot_note') }}</p>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
			<b-pagination
				v-if="pagination.total_pages > 1"
				v-model="pagination.current_page"
				:total-rows="pagination.total"
				:per-page="pagination.per_page"
				align="center"
				@change="getDataFiltered" />
			<div />
		</b-col>
	</b-row>
</template>

<script>

import WeeklyCommissions from '@/util/WeeklyCommissions';
import {
	WeeklyCommissions as messages, Grids, Products, Ranks,
} from '@/translations';
import { PAGINATION } from '@/settings/RequestReply';
import { admin } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import SubRanks from '@/mixins/SubRanks';
import FiltersParams from '@/mixins/FiltersParams';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'LeadershipMatchingBonus',
	messages: [messages, Grids, Products, Ranks],
	mixins: [FiltersParams, DashboardRedirect, SubRanks],
	data() {
		return {
			leadershipMatchingBonus: new WeeklyCommissions(),
			userId: {},
			admin,
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
		};
	},
	computed: {
		loading() {
			return !!this.leadershipMatchingBonus.data.loading;
		},
		pagination() {
			return this.leadershipMatchingBonus.data.pagination;
		},
		data() {
			try {
				const { data } = this.leadershipMatchingBonus.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			return !!this.data.length;
		},
	},
	mounted() {
		if (this.$route.params.week_id) {
			this.weekNumber = this.$route.params.week_id;
		}
		if (this.$route.params.user_id) {
			this.$route.query.user_id = this.$route.params.user_id;
			this.userId = { user_id: this.$route.params.user_id };
		}
		this.getData();
	},
	methods: {
		getData() {
			this.leadershipMatchingBonus.getMatchingBonus(this.weekNumber, this.userId);
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters, ...this.userId };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.leadershipMatchingBonus.getMatchingBonus(this.weekNumber, options);
		},
	},
};
</script>
