<template>
	<b-row>
		<b-col>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th class="border-top-0">
								#
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('users.id')">
								{{ translate('user_id') }}
								<sort field="users.id" />
							</th>
							<th
								class="border-top-0 pointer"
								@click="sortByField('users.username')">
								{{ translate('username') }}
								<sort field="users.username" />
							</th>
							<th
								class="border-top-0 pointer text-right"
								@click="sortByField('amount')">
								{{ translate('total') }}
								<sort field="amount" />
							</th>
							<th
								class="border-top-0 pointer text-center">
								{{ translate('valid_customers') }}
							</th>
							<th
								class="border-top-0 pointer">
								{{ translate('customers') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<tr
							v-for="(item, index) in data"
							:key="index">
							<td class="align-middle">
								{{
									`${(((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1))}${item.attributes.is_adjustment ? '*' : ''}`
								}}
							</td>
							<td class="align-middle">
								<span
									v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
									class="badge badge-primary pointer"
									@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
									{{ item.attributes.user_id }}
								</span>
								<span
									v-else
									class="badge badge-primary">
									{{ item.attributes.user_id }}
								</span>
							</td>
							<td class="align-middle">
								{{ item.attributes.username }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.amount }}
							</td>
							<td class="align-middle">
								{{ item.attributes.valid_customers }}
							</td>
							<td class="align-middle">
								{{ item.attributes.customers }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
			<b-pagination
				v-if="pagination.total_pages > 1"
				v-model="pagination.current_page"
				:total-rows="pagination.total"
				:per-page="pagination.per_page"
				align="center"
				@change="getDataFiltered" />
			<div />
		</b-col>
	</b-row>
</template>

<script>

import WeeklyCommissions from '@/util/WeeklyCommissions';
import {
	WeeklyCommissions as messages, Grids,
} from '@/translations';
import { PAGINATION } from '@/settings/RequestReply';
import { admin, distributor } from '@/settings/Roles';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';

export default {
	name: 'StriveForFive',
	messages: [messages, Grids],
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			striveForFive: new WeeklyCommissions(),
			userId: {},
			admin,
			distributor,
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		loading() {
			return !!this.striveForFive.data.loading;
		},
		pagination() {
			return this.striveForFive.data.pagination;
		},
		data() {
			try {
				const { data } = this.striveForFive.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			return !!this.data.length;
		},
	},
	mounted() {
		if (this.$route.params.week_id) {
			this.weekNumber = this.$route.params.week_id;
		}
		if (this.$route.params.user_id) {
			this.$route.query.user_id = this.$route.params.user_id;
			this.userId = { user_id: this.$route.params.user_id };
		}
		this.getData();
	},
	methods: {
		getData() {
			this.striveForFive.getStriveForFive(this.weekNumber, this.userId);
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters, ...this.userId };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.striveForFive.getStriveForFive(this.weekNumber, options);
		},
	},
};
</script>
